<template>
  <div class="form-datetime">
    <Datetime
      :class="{ 'has-error': hasErros }"
      type="datetime"
      v-model="model"
      :format="format"
      :placeholder="label"
    />
    <div v-if="hasErros" class="error-info">
      <span>{{ errors[0] }}</span>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { Settings } from 'luxon'
Settings.defaultLocale = 'pt'

import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

import * as moment from 'moment'
export default {
  name: 'FormDatetimePicker',
  components: { Datetime },
  props: {
    value: {
      type: String,
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    format: {
      type: String,
      default: 'dd/MM/yyyy HH:mm:ss',
      required: false
    },
    formatter: {
      type: String,
      default: 'DD/MM/YYYY HH:mm:ss',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  directives: {
    mask
  },
  data: () => ({
    visibled: false,
    model: '',
    touched: false,
    menu: false,
    dateFormated: ''
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    },
    model: {
      handler(newVal) {
        this.$emit('change', newVal)
        this.$emit('input', newVal)
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    hasErros() {
      return this.validation && this.validation.$dirty && this.errors.length
    },
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (!this.validation.required) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        return errorsMess
      }
      return []
    },
    placeholder() {
      return this.model ? this.formatDate(this.model) : this.label
    }
  },
  methods: {
    onBlur() {
      this.$emit('blur', this.model)
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    },
    formatDate(date) {
      return date ? moment(date).format(this.formatter) : ''
    }
  }
}
</script>

<style lang="scss">
.form-datetime {
  margin-bottom: 20px;
}
.vdatetime {
  border: solid 1px #ccc;
  padding: 8px 24px;
  border-radius: 26px;
  width: 100%;
  .vdatetime-input {
    width: 100%;
  }
}
.vdatetime.has-error {
  border: solid 2px red;
}
.error-info {
  color: #ff5252 !important;
  font-size: 12px;
  padding: 5px 12px;
}
</style>
