import moment from 'moment'

export const getFormattedDate = (date, isDefaultTZ, hasTimezone) => {
  const separatorTime = date.includes('T') ? 'T' : ' '
  const arrayDatetime = date ? date.split(separatorTime) : null
  const separatorDate = date.includes('/') ? '/' : '-'
  const arrayDate =
    arrayDatetime && arrayDatetime[0]
      ? arrayDatetime[0].split(separatorDate)
      : null
  let dateString = ''
  if (arrayDate) {
    if (date.includes('/')) {
      dateString = arrayDatetime[1]
        ? `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}T${arrayDatetime[1]}`
        : `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`
    } else {
      let time = arrayDatetime[1] ? arrayDatetime[1].split('-') : []
      dateString = time[0] ? `${arrayDatetime[0]}T${time[0]}` : arrayDatetime[0]
    }
  }
  let formatedDate =
    dateString && isDefaultTZ ? dateString + '-03:00' : dateString
  return hasTimezone
    ? moment(formatedDate, moment.ISO_8601).format()
    : formatedDate
}
