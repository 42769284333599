<template>
  <SidePopup
    :title="
      mode == 'add' ? 'Criar combo promocional' : 'Editar combo promocional'
    "
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="loading"
    routeback="/fornecedor/comercial/combos-promocionais"
    :closeOnOutsideClick="!loading"
  >
    <Grid grid-template-columns="repeat(3, 1fr)">
      <FormField label="Nome" v-model="form.nome" :validation="$v.form.nome" />

      <FormField
        label="Descrição"
        v-model="form.descricao"
        :validation="$v.form.descricao"
      />

      <FormField
        label="Código interno"
        v-model="form.id_combopromocional_erp"
      />

      <FormDatetimePicker
        dense
        v-model="form.vigencia_inicial"
        label="Data inicial"
        :validation="$v.form.vigencia_inicial"
      />

      <FormDatetimePicker
        dense
        v-model="form.vigencia_final"
        label="Data final"
        :validation="$v.form.vigencia_final"
      />
    </Grid>

    <Grid grid-template-columns="repeat(3, 1fr)">
      <FormCheckbox label="Disponível" v-model="form.disponivel" />
    </Grid>

    <LineDivider><h4>Imagens do combo promocional</h4></LineDivider>
    <DragDropUploader
      v-model="form.imagem_combo_promocional"
      :upload-url="endpointUploadUrl"
      :upload-multiple="false"
      :loading="imageLoading"
      initial-icon-class="fas fa-cloud-upload-alt"
      @remove="removeImage"
    >
    </DragDropUploader>

    <div v-if="initialized">
      <LineDivider>
        <h4>Segmento de clientes</h4>
      </LineDivider>
      <FormTags
        placeholder="Pesquise e adicione segmento de clientes"
        v-model="form.segmentocliente"
        :endpoint="endpointSegmentoCliente"
        keyid="idsegmentocliente"
        keyvalue="nome"
        :search-with-spaces="true"
        :validation="$v.form.segmentocliente"
      >
        <template v-slot:item="{ item }">
          <div class="d-flex flex-column align-start">
            <p style="margin-bottom:0!important">{{ item.nome }}</p>
          </div>
        </template>
      </FormTags>
    </div>

    <div v-if="initialized">
      <LineDivider />

      <div class="d-flex justify-center">
        <FormSelect
          label="Selecione a Entidade"
          placeholder="Selecione uma entidade."
          v-model="entidade"
          :options="entidades"
        />
      </div>

      <div>
        <v-row>
          <v-col class="d-flex justify-center" cols="4" xs="4" sm="4" md="4">
            <FormAutocomplete
              v-if="entidade === 'Categoria'"
              label="Categoria"
              placeholder="Pesquise aqui a categoria desejada"
              chips
              v-model="form_itens.id_entidade"
              :clearable="true"
              :options="setoresFolha"
              :multiple="false"
              :validation="$v.form_itens.id_entidade"
              :validar="validar"
            />

            <FormTags
              v-else
              style="width: 100%;"
              placeholder="Pesquisa pelo nome, código interno ou código de barras"
              label="Produto"
              queryname="filter"
              keyid="idcadastroextraproduto"
              keyvalue="text"
              v-model="form_itens.produto"
              :searchWithSpaces="true"
              :endpoint="endpointProdutos"
              :multiple="false"
              :clearable="true"
              :validation="$v.form_itens.produto"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex flex-column align-start">
                  <p style="margin-bottom:0!important">{{ item.nome }}</p>
                  <small v-if="item.id_produto_erp">
                    Cód. interno: {{ item.id_produto_erp }}
                  </small>

                  <small v-if="item.codigobarras">
                    Cód. barras: {{ item.codigobarras }}
                  </small>
                </div>
              </template>
            </FormTags>
          </v-col>

          <v-col cols="1" xs="1" sm="2" md="2">
            <FormField
              label="Fator de preço"
              v-model="form_itens.fator_preco_entidade"
              :validation="$v.form_itens.fator_preco_entidade"
              :money="moneyMask"
            />
          </v-col>

          <v-col cols="2" xs="2" sm="2" md="2">
            <v-tooltip
              v-if="entidade === 'Produto'"
              v-model="tooltipVisible"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="{ ...on, click: hideTooltip }">
                  <FormField
                    label="Quantidade mínima"
                    v-model="form_itens.minporcompra"
                    :validation="$v.form_itens.minporcompra"
                    :money="numberMask"
                  />
                </div>
              </template>
              Quantidade adicionada quando o cliente clicar em adicionar ao
              carrinho dentro do combo.
            </v-tooltip>

            <FormField
              v-else
              label="Quantidade mínima"
              v-model="form_itens.minporcompra"
              :validation="$v.form_itens.minporcompra"
              :money="numberMask"
            />
          </v-col>

          <v-col cols="2" xs="2" sm="2" md="2">
            <FormField
              label="Quantidade máxima"
              v-model="form_itens.maxporcompra"
              :validation="$v.form_itens.maxporcompra"
              :money="numberMask"
            />
          </v-col>

          <v-col
            cols="2"
            xs="2"
            sm="2"
            md="2"
            class="d-flex justify-center"
            v-if="entidade === 'Produto'"
          >
            <FormCheckbox
              style="margin: 0;"
              label="Obrigatório"
              v-model="form_itens.obrigatorio"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" xs="2" sm="2" md="2" v-if="entidade === 'Categoria'">
            <div class="d-flex mt-2">
              <h4>SKU's distintos:</h4>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
                </template>
                Campo de validação da quantidade mínima e máxima de SKU's
                distintos
              </v-tooltip>
            </div>
          </v-col>

          <v-col cols="2" xs="2" sm="2" md="2" v-if="entidade === 'Categoria'">
            <FormField
              label="Quantidade mínima"
              v-model="form_itens.sku_min"
              :money="numberMask"
            />
          </v-col>

          <v-col cols="2" xs="2" sm="2" md="2" v-if="entidade === 'Categoria'">
            <FormField
              label="Quantidade máxima"
              v-model="form_itens.sku_max"
              :money="numberMask"
            />
          </v-col>

          <v-col
            cols="2"
            xs="2"
            sm="2"
            md="2"
            class="d-flex justify-center"
            v-if="entidade === 'Categoria'"
          >
            <FormCheckbox
              style="margin: 0;"
              label="Obrigatório"
              v-model="form_itens.obrigatorio"
            />
          </v-col>

          <v-col
            cols="2"
            xs="2"
            sm="2"
            md="2"
            :offset-md="entidade === 'Categoria' ? 0 : 10"
          >
            <FormButton
              style="width: 100%;"
              type="primario"
              text="Adicionar"
              @click="addEntidade"
            />
          </v-col>
        </v-row>
      </div>

      <DataTable
        :headers="headers_itenscombo"
        :data="tabela_itenscombo"
        ref="datatable_itenscombo"
        :auto-search="false"
        :hideFooter="true"
        sortBy="nome"
        :sortDesc="true"
        :disableLoading="mode == 'add' ? true : false"
      >
        <template v-slot:acoes="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
        <template v-slot:obrigatorio="{ row }">
          <FormCheckBox v-model="row.obrigatorio" />
        </template>
      </DataTable>
    </div>

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import Grid from '@/components/Grid'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import FormDatetimePicker from '@/components/form/FormDatetimePicker'
import FormCheckbox from '@/components/form/FormCheckbox'
import LineDivider from '@/components/LineDivider'
import FormTags from '@/components/form/FormTags'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import mixinUtils from '@/mixins/utils'
import { mapGetters } from 'vuex'
import { getFormattedDate } from '../../../utils/datetime'
import {
  COMBO_PROMOCIONAL_LOAD,
  COMBO_PROMOCIONAL_SAVE
} from '@/store/actions/comboPromocional'
import * as _ from 'lodash'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import {
  COMBO_PROMOCIONAL_ITENS_LOAD,
  COMBO_PROMOCIONAL_LOAD_IMAGES,
  COMBO_PROMOCIONAL_REMOVE_IMAGE
} from '../../../store/actions/comboPromocional'
import DataTable from '@/components/DataTable'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import DragDropUploader from '@/components/Drag-drop-uploader'
import FormCheckBox from '@/components/form/FormCheckbox'
import FormSelect from '@/components/form/FormSelect'

export default {
  name: 'ComboPromocionalForm',
  mixins: [mixinUtils],
  components: {
    SidePopup,
    FormButton,
    LineDivider,
    Grid,
    FormField,
    DragDropUploader,
    FormCheckbox,
    FormAutocomplete,
    FormDatetimePicker,
    FormTags,
    DataTable,
    FormButtonMenu,
    FormCheckBox,
    FormSelect
  },
  validations: {
    form: {
      nome: { required },
      descricao: { required },
      vigencia_inicial: { required },
      vigencia_final: { required }
    },
    form_itens: {
      id_entidade: {
        required(value) {
          if (this.entidade === 'Categoria') {
            return required(value)
          }
          return true
        }
      },
      fator_preco_entidade: { required },
      minporcompra: { required },
      maxporcompra: { required },
      produto: {
        required(value) {
          if (this.entidade === 'Produto') {
            return required(value)
          }
          return true
        }
      }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      idcombopromocional: null,
      id_combopromocional_erp: null,
      idfornecedor: null,
      nome: null,
      descricao: null,
      vigencia_inicial: null,
      vigencia_final: null,
      tipo_campanha: '%',
      tipo_desconto: 'SQP',
      disponivel: null,
      segmentocliente: [],
      imagem_combo_promocional: []
    },
    form_itens: {
      descricao: null,
      id_entidade: null,
      fator_preco_entidade: 0,
      minporcompra: null,
      maxporcompra: null,
      sku_min: null,
      sku_max: null,
      produto: null,
      obrigatorio: false
    },
    entidade: 'Categoria',
    entidades: [
      { text: 'Categoria', value: 'Categoria' },
      { text: 'Produto', value: 'Produto' }
    ],
    validar: true,
    tabStart: 0,
    loading: false,
    initialized: false,
    dateFormat: 'DD/MM/YYYY HH:mm:ss',
    dateFormatBD: 'YYYY-MM-DD HH:mm:ss',
    currentPage: 0,
    startPos: 0,
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 4,
      masked: false
    },
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    },
    productsToAdd: [],
    clientsToAdd: [],
    produtosOptions: [],
    headers_itenscombo: [
      { text: 'Entidade', value: 'tipo_entidade' },
      { text: 'Nome', value: 'descricao' },
      { text: 'Fator de preço', value: 'fator_preco_entidade' },
      { text: 'Quantidade mínima', value: 'minporcompra' },
      { text: 'Quantidade máxima', value: 'maxporcompra' }
    ],
    tabela_itenscombo: [],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    imageLoading: false,
    promotionalComboImageSeed: '',
    tooltipVisible: false
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getSetoresFolhas',
      'getIdListSegmentos'
    ]),
    clientOptions() {
      return { key: 'nome', id: 'idcliente', payloadProp: 'idcliente' }
    },
    optionsComboPromocional() {
      return [
        {
          text: 'Fator de preço (%)',
          value: '%'
        },
        {
          text: 'Preço fixo',
          value: 'R$'
        }
      ]
    },
    optionsTipoDesconto() {
      return [
        {
          text: 'Mix por quantidade mínima',
          value: 'MQT'
        },
        {
          text: 'Mix por Intervalo de quantidade',
          value: 'MIQ'
        },
        {
          text: 'Qtde subcategoria de produto',
          value: 'SQP'
        }
      ]
    },
    isUpdating() {
      return this.form && this.form.idpolitica ? true : false
    },
    endpointSegmentoCliente() {
      return `/api/v4/segmento-cliente/${this.getFornecedorId}`
    },
    endpointProdutos() {
      return `/api/v1/produtos/s2format-simple?idfornecedor=${this.getFornecedorId}`
    },
    endpointUploadUrl() {
      return `/api/v4/promotional-combo/${this.getFornecedorId}/${this.promotionalComboImageId}/imagem`
    },
    promotionalComboImageId() {
      let idcombopromocional =
        'novo-combo-promocional-' +
        (this.form.promotionalComboImageSeed ||
          this.generatePromotionalComboImageSeed())
      if (this.form.idcombopromocional) {
        idcombopromocional = this.form.idcombopromocional
      }
      return idcombopromocional
    },
    setoresFolha() {
      return this.getSetores()
    }
  },
  created() {
    let idRoute = this.$route.params.id
    this.form.promotionalComboImageSeed = this.generatePromotionalComboImageSeed()

    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.initialized = true
    }
  },
  methods: {
    getData(id) {
      this.loading = true
      this.$store
        .dispatch(COMBO_PROMOCIONAL_LOAD, id)
        .then(resp => {
          this.loadData(resp)
          this.$store
            .dispatch(COMBO_PROMOCIONAL_ITENS_LOAD, id)
            .then(resp => {
              this.loadDataItens(resp)
            })
            .catch(() => (this.loading = false))
        })
        .catch(() => (this.loading = false))
    },
    loadData(promotionalCombo) {
      if (!promotionalCombo) return
      this.form.permitirEditarVinculos = false

      this.form.nome = promotionalCombo.nome
      this.form.descricao = promotionalCombo.descricao
      this.form.idfornecedor =
        promotionalCombo.idfornecedor || this.getFornecedorId
      this.form.idcombopromocional = promotionalCombo.idcombopromocional
      this.form.id_combopromocional_erp =
        promotionalCombo.id_combopromocional_erp
      this.form.disponivel = promotionalCombo.disponivel
      this.form.vigencia_inicial = this.formatData(
        _.get(promotionalCombo, 'vigencia_inicial', '')
      )
      this.form.vigencia_final = this.formatData(
        _.get(promotionalCombo, 'vigencia_final', '')
      )

      this.form.tipo_campanha = promotionalCombo.tipo_campanha
      this.form.tipo_desconto = promotionalCombo.tipo_desconto

      this.form.segmentocliente = (promotionalCombo.idssegmentos || []).map(
        idsegmento => {
          return {
            idsegmentocliente: idsegmento
          }
        }
      )

      this.loadImages(this.form.idcombopromocional)

      this.loading = false
      this.initialized = true
    },
    loadDataItens(promotionalComboItens) {
      if (!promotionalComboItens) return

      this.tabela_itenscombo = promotionalComboItens

      this.loading = false
      this.initialized = true
    },
    getSetores() {
      const setores = this.getSetoresFolhas.reduce((folhas, folha) => {
        if (this.getIdListSegmentos.includes(folha.idsegmento)) {
          folhas.push({
            text: `${folha.segmento}/${folha.rotulo}`,
            value: folha.id
          })
        }
        return folhas
      }, [])

      return setores
    },
    normalizarPonto(newVal, oldVal) {
      return newVal || oldVal
    },
    normalizePricefactor() {
      if (this.form.fator < 0) {
        this.form.fator = null
      }
      if (this.form.valor < 0) {
        this.form.valor = null
      }
    },
    formatDatetime(date) {
      return date ? moment(date).format(this.dateFormat) : ''
    },
    formatData(date) {
      if (!date) return
      return getFormattedDate(date, false, true)
    },
    validateForm(form) {
      let valid = true
      let err = ''

      if (
        form.vigencia_inicial === form.vigencia_final ||
        moment(form.vigencia_inicial).isAfter(form.vigencia_final)
      ) {
        err = 'A data inicial não pode ser maior ou igual à data final'
        this.$vueOnToast.pop('error', err)
        return false
      } else if (this.tabela_itenscombo.length <= 0) {
        err = 'Nenhuma categoria foi adicionada no combo promocional'
        this.$vueOnToast.pop('error', err)
        return false
      }

      return valid
    },
    prepareDataToSubmit() {
      this.tabela_itenscombo = this.tabela_itenscombo.map(itenscombo => {
        return {
          ...itenscombo,
          maxporcompra: itenscombo.maxporcompra.replaceAll(' ', ''),
          minporcompra: itenscombo.minporcompra.replaceAll(' ', ''),
          produto: _.get(itenscombo, 'id_entidade', null)
        }
      })

      const data = {
        ...this.form,
        id_combopromocional_erp: this.form.id_combopromocional_erp
          ? this.form.id_combopromocional_erp
          : '',
        vigencia_inicial: moment(this.form.vigencia_inicial).format(
          this.dateFormatBD
        ),
        vigencia_final: moment(this.form.vigencia_final).format(
          this.dateFormatBD
        ),
        segmentocliente: this.form.segmentocliente.filter(
          segmento => segmento.idsegmentocliente !== null
        ),
        itens_combo: this.tabela_itenscombo,
        imagem_combo_promocional: this.promotionalComboImageId
      }
      return data
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      const data = this.prepareDataToSubmit()

      if (this.validateForm(data)) {
        this.loading = true
        this.$store
          .dispatch(COMBO_PROMOCIONAL_SAVE, data)
          .then(async result => {
            if (result) {
              this.notifySucess(this.isUpdating)
            } else {
              this.notifySucess(this.isUpdating)
            }
          })
          .catch(this.notifyError)
      }
    },
    changeLoading(val) {
      this.loading = val
    },
    notifyError(err) {
      const msg = _.get(err, 'responseJSON.Mensagem', 'Erro ao buscar produtos')
      this.$vueOnToast.pop('error', msg)
      this.changeLoading(false)
    },
    notifySucess(isUpdating) {
      let msg = isUpdating
        ? 'Combo promocional alterado com sucesso'
        : 'Combo promocional cadastrado com sucesso'
      this.$vueOnToast.pop('success', msg)
      this.changeLoading(false)
      this.onClose(true)
    },
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/comercial/combos-promocionais')
      }
    },
    addEntidade() {
      this.$v.form_itens.$touch()
      if (this.$v.form_itens.$invalid) return (this.validar = true)

      this.form_itens.fator_preco_entidade =
        this.form_itens.fator_preco_entidade &&
        this.form_itens.fator_preco_entidade.replace(',', '.')

      if (this.entidade === 'Categoria') {
        if (
          this.form_itens.id_entidade &&
          this.tabela_itenscombo.find(
            item => item.id_entidade == this.form_itens.id_entidade
          )
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'A categoria selecionada já foi inserida.'
          )
        }

        if (!this.form_itens.id_entidade) {
          return this.$vueOnToast.pop('warning', 'Selecione uma categoria')
        } else if (
          Number(this.form_itens.sku_min) > Number(this.form_itens.sku_max)
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'A quantidade mínima do SKU não pode ser maior que a quantidade máxima do SKU'
          )
        } else if (
          Number(this.form_itens.sku_min) > Number(this.form_itens.minporcompra)
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'A quantidade mínima do SKU não pode ser maior que a quantidade mínima da categoria'
          )
        } else if (
          Number(this.form_itens.sku_max) > Number(this.form_itens.maxporcompra)
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'A quantidade máxima do SKU não pode ser maior que a quantidade máxima da categoria'
          )
        }
      } else if (this.entidade === 'Produto') {
        if (
          this.form_itens.produto &&
          this.tabela_itenscombo.find(item => {
            if (item.id_entidade) {
              if (
                item.id_entidade ===
                this.form_itens.produto.idcadastroextraproduto
              ) {
                return item
              }
            }
          })
        ) {
          return this.$vueOnToast.pop(
            'warning',
            'O produto selecionado já foi inserido.'
          )
        }

        if (Number(this.form_itens.minporcompra) <= 0) {
          return this.$vueOnToast.pop(
            'warning',
            'A quantidade mínima não pode ser igual a 0 (zero)'
          )
        }

        if (!this.form_itens.produto) {
          return this.$vueOnToast.pop('warning', 'Selecione um produto')
        }
      }

      if (Number(this.form_itens.maxporcompra) <= 0) {
        return this.$vueOnToast.pop(
          'warning',
          'A quantidade máxima não pode ser igual a 0 (zero)'
        )
      } else if (
        Number(this.form_itens.minporcompra) >
        Number(this.form_itens.maxporcompra)
      ) {
        return this.$vueOnToast.pop(
          'warning',
          'A quantidade mínima não pode ser maior que a quantidade máxima'
        )
      } else if (Number(this.form_itens.fator_preco_entidade) <= 0) {
        return this.$vueOnToast.pop(
          'warning',
          'O fator de preço não por ser igual a 0 (zero)'
        )
      }

      const setores = this.getSetores()

      this.form_itens.descricao =
        this.entidade === 'Categoria'
          ? setores.find(setor => setor.value == this.form_itens.id_entidade)
              .text
          : _.get(this.form_itens.produto, 'nome', null)

      this.form_itens.id_entidade =
        this.entidade === 'Produto'
          ? _.get(
              this.form_itens.produto,
              'idcadastroextraproduto',
              '00000000-0000-0000-0000-000000000000'
            )
          : this.form_itens.id_entidade

      this.tabela_itenscombo.push({
        ...this.form_itens,
        tipo_entidade: this.entidade
      })

      this.validar = false
      this.cleanFormItens()
      this.$v.$reset()

      this.$vueOnToast.pop(
        'success',
        this.entidade === 'Categoria'
          ? 'Categoria adicionada com sucesso!'
          : 'Produto adicionado com sucesso!'
      )
    },
    cleanFormItens() {
      this.form_itens = {
        descricao: null,
        id_entidade: null,
        fator_preco_entidade: 0,
        minporcompra: 0,
        maxporcompra: 0,
        sku_min: null,
        sku_max: null,
        produto: [],
        obrigatorio: false
      }
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          return this.onUpdate(item)
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      this.tabela_itenscombo = this.tabela_itenscombo.filter(item_tabela => {
        if (item_tabela.id_entidade) {
          if (item_tabela.id_entidade !== item.id_entidade) {
            return item_tabela
          }
        } else if (
          item_tabela.produto.idcadastroextraproduto !== item.id_entidade
        ) {
          return item_tabela
        }
      })
    },
    generatePromotionalComboImageSeed() {
      return '' + Math.floor(Math.random() * Math.pow(10, 20))
    },
    loadImages(idcombopromocional) {
      this.imageLoading = true
      this.$store
        .dispatch(COMBO_PROMOCIONAL_LOAD_IMAGES, { id: idcombopromocional })
        .then(respImg => {
          this.imageLoading = false
          this.form.imagem_combo_promocional = _.get(respImg, 'imagens', [])
        })
        .catch(() => (this.imageLoading = false))
    },
    removeImage(imagem) {
      this.$store
        .dispatch(COMBO_PROMOCIONAL_REMOVE_IMAGE, {
          id: this.promotionalComboImageId,
          imagem
        })
        .then(() => {
          setTimeout(() => this.loadImages(this.promotionalComboImageId), 500)
        })
    },
    hideTooltip() {
      this.tooltipVisible = false
    }
  },
  watch: {
    entidade() {
      this.$v.$reset()
      this.cleanFormItens()
    },
    tabela_itenscombo(entidades) {
      const entidadeProduto = entidades.every(
        entidade => entidade.tipo_entidade === 'Produto'
      )
      const headersParaRemover = ['sku_min', 'sku_max', 'obrigatorio', 'acoes']

      this.headers_itenscombo = this.headers_itenscombo.filter(
        header => !headersParaRemover.includes(header.value)
      )

      const novosHeaders = entidadeProduto
        ? [
            { text: 'Obrigatório', value: 'obrigatorio' },
            { text: 'Opções', value: 'acoes' }
          ]
        : [
            { text: 'SKU Distintos mínimo', value: 'sku_min' },
            { text: 'SKU Distintos máximo', value: 'sku_max' },
            { text: 'Obrigatório', value: 'obrigatorio' },
            { text: 'Opções', value: 'acoes' }
          ]

      this.headers_itenscombo.push(...novosHeaders)
    }
  }
}
</script>
